import { Box, BoxProps, Container, SystemStyleInterpolation } from '@chakra-ui/react'
import type { ComponentProps, FunctionComponent, ReactNode } from 'react'

import Image from 'components/elements/Image'

interface Image extends RequiredFields<Partial<ComponentProps<typeof Image>>, 'src'> { }

interface HeroImageProps extends BoxProps, Omit<ComponentProps<typeof Container>, 'ref'> {
  image: string | Image
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'HeroImage'

const HERO_HEIGHT = { base: 300, md: 400, lg: 505, xl: 600 }
const styles = {
  grid: {
    color: 'white',
    position: 'relative',
    display: 'grid',
    overflow: 'hidden',
    height: 'auto',
  },
  image: {
    maxW: '100%',
    marginInline: 'auto',
    gridArea: '1 / -1',
    minH: '100%',
    minWidth: '100%',
    userSelect: 'none',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // White with 50% transparency
    zIndex: 1, // Make sure it overlays the image
  },
  wrapper: {
    minWidth: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    maxW: 'desktop',
    gridArea: '1 / -1',
    py: '10',
    zIndex: 2, // Ensure content is above the overlay
  },
} as const satisfies SystemStyleInterpolation;

const HeroImage: FunctionComponent<HeroImageProps> = ({
  maxW = 'full',
  h = HERO_HEIGHT,
  image,
  children,
  ...props
}) => {
  return (
    <Box
      data-testid={TAG}
      as="header"
      {...styles.grid}
      bgColor={typeof image === 'string' ? 'transparent' : image.backgroundColor}
      h={h}
      maxW={maxW}
      maxH={h}
    >
      <Image
        {...styles.image}
        alt=""
        role="presentation"
        priority={true}
        loading="eager"
        width={{
          base: 480,
          sm: 720,
          md: 960,
          lg: 1200,
          xl: 1440,
          '2xl': 1920,
        }}
        height={h}
        {...(typeof image === 'string' ? {} : image)}
        src={typeof image === 'string' ? image : image.src}
      />

      {/* Transparent Overlay */}
      <Box {...styles.overlay} />

      <Container {...styles.wrapper} h={h} {...props}>
        {children}
      </Container>
    </Box>
  );
};

export default HeroImage;
