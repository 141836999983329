import {
  Button,
  Container,
  Grid,
  Heading,
  Icon,
  List,
  ListItem,
  Stack,
  SystemStyleInterpolation,
  Text,
} from '@chakra-ui/react'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { ComponentProps, FunctionComponent } from 'react'

import ApplyIcon from 'assets/apply_for_icon.svg?component'
import CheckIcon from 'assets/icon_check.svg?component'
import Image from 'components/elements/Image'
import withPageStaticProps from 'utils/next/withPageStaticProps'

const CONTACT_EMAIL_HREF = 'https://www.kanthal.com/en/contact-us'

const TAG = 'ApplyForAccount'

const bulletPoints = [
  'pages.login.request.applyPoint.1',
  'pages.login.request.applyPoint.2',
  'pages.login.request.applyPoint.3',
] as const satisfies ReadonlyArray<TranslationKeys['common']>

const IMAGE = {
  src: '/images/hero_login2.jpg',
  role: 'presentation',
  blurDataURL:
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAL0lEQVR4nGNQEhSQY2BQ5WVwMTFjMHVOKk5JSE1I9Y5rY2CQMLt+6sz/H39CU/sBo08Mo0GKTKkAAAAASUVORK5CYII=',
} as const satisfies Partial<ComponentProps<typeof Image>>

const styles = {
  wrapper: {
    maxW: 'desktop-content',
    mt: '10',
    mb: '20',
  },
  box: {
    gridTemplateColumns: {
      base: '1fr',
      md: 'minmax(auto, 400px) minmax(50%, 1fr)',
    },
    bg: 'gray.50',
    justifyContent: 'flex-start',
  },
  img: {
    height: 256,
    width: 400,
    style: {
      width: '100%',
      height: '100%',
    },
  },
  incentive: {
    spacing: '6',
    align: 'flex-start',
    pt: '6',
    pb: '8',
    px: { base: '4', lg: '8' },
    flexGrow: '1',
  },
} as const satisfies SystemStyleInterpolation

const TRANS_COMPONENTS = {
  span: <Text color="black" as="span" />,
}

const ApplyForAccount: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container data-testid={TAG} {...styles.wrapper}>
      <Grid {...styles.box}>
        <Image {...styles.img} {...IMAGE} alt="" />

        <Stack {...styles.incentive}>
          <Heading as="h2" size="xl" textTransform="uppercase" fontWeight="normal">
            <Text as="span" color="primary.red">
              <Trans i18nKey="pages.login.request.title" components={TRANS_COMPONENTS} />
            </Text>
          </Heading>

          <Text as="p" color="primary.black" fontWeight="bold">
            <Trans i18nKey="pages.login.subtitle" components={TRANS_COMPONENTS} />
          </Text>

          <Stack as={List} direction="column" spacing="2">
            {bulletPoints.map((point) => (
              <ListItem key={point} display="flex" alignItems="center">
                <Icon aria-hidden="true" as={CheckIcon} color="primary.red" boxSize="1.5rem" mr="3" />
                <Text as="span">{t(point)}</Text>
              </ListItem>
            ))}
          </Stack>

          <Text as="p" color="primary.black">
            <Trans i18nKey="pages.login.request.after" components={TRANS_COMPONENTS} />
          </Text>

          <Button
            as={Link}
            href={CONTACT_EMAIL_HREF}
            leftIcon={<Icon aria-hidden="true" as={ApplyIcon} boxSize="1rem" />}
          >
            {t('pages.login.request.button')}
          </Button>
        </Stack>
      </Grid>
    </Container>
  )
}

ApplyForAccount.displayName = TAG

export default ApplyForAccount

export const getStaticProps = withPageStaticProps()
