import { Stack, StackProps } from '@chakra-ui/react'
import type { FunctionComponent, ReactNode } from 'react'

import HeroImage from 'components/modules/Hero/HeroImage'

/**
 * Used for E2E tests.
 */
const HERO_HEIGHT = { base: 380, md: 520 }

const DEFAULT_IMAGE = {
  src: '/images/kanthal-eshop.png',
  backgroundColor: '#4c4c4c',
  blurDataURL:
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAGCAIAAABFHomwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVR4nDXEIauDQAAAYEEGIscLQ+4QLBaLzWCwL6yLfVhEhu14WAeH4UAEwwXbqgzzcU38A9cGQ9YsBhnYLA987Auf0nVd3/dSynEcX6/XPM/Lsrzf73Ech2F4Pp+EECnluq6fz2fbNkUIwRi73+9d1wkh+r5v2xZjXFVVGIZt2xZF8Xg8pmmSUnLOlXrHGKuqKs/zJElut1scx4SQsiybpuGcU0pPp5PjOBBCJU3Tuq4xxlEUJUmS5zn7opSmaRrHseu6qqpCCD3PU353GOMsy67X6+VyOZ/Pvu87joMQAgBomnY8HiGEpmkGQaDYto0QsizLNE0AgKqqh8NB1/X/AQA/O7AzDOMPy8B/S75GtIsAAAAASUVORK5CYII=',
}

interface HeroLoginProps extends StackProps {
  children: ReactNode
}

const HeroLogin: FunctionComponent<HeroLoginProps> = ({ children, ...props }) => (
  <HeroImage h={HERO_HEIGHT} image={DEFAULT_IMAGE}>
    <Stack direction="column" w="full" alignItems="center" justifyContent="center" spacing="10" {...props}>
      {children}
    </Stack>
  </HeroImage>
)

export default HeroLogin
